import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import FullHeightContentCard from 'src/component/common/layout/FullHeightContentCard';
import DatePicker from 'src/component/common/picker/DatePicker';
import { LiveStylePicker } from 'src/component/common/picker/LiveStylePicker';
import LiverLiveTimeTableView from 'src/component/screen/liverlivetimetable/LiverLiveTimeTableView';
import { LiveTime } from 'src/model/liveTime';
import { liveRepository } from 'src/model/repository/liveRepository';
import { dateTimeToShortFormat } from 'src/util/dateTime';

type Props = {
  liverId: number;
};

export default function LiverLiveTimeTableCard(props: Props): JSX.Element {
  const [liveStyle, setLiveStyle] = useState<number | null>(null);
  const [dateTime, setDateTime] = useState<DateTime | null>(null);
  const [loading, setLoading] = useState(false);
  const [liveTimeList, setLiveTimeList] = useState<LiveTime[]>([]);

  function onLiveStyleChanged(liveStyle: number | null) {
    setLiveStyle(liveStyle);
  }

  function onDateChanged(dateTime: DateTime | null) {
    setDateTime(dateTime);
  }

  useEffect(() => {
    setLiveTimeList([]);
    setLoading(true);

    let isSecretly: boolean | null;

    switch (liveStyle) {
      case 0:
        isSecretly = false;
        break;
      case 1:
        isSecretly = true;
        break;
      default:
        isSecretly = null;
        break;
    }

    liveRepository
      .fetchLiveTime(props.liverId, isSecretly, dateTime)
      .then((liveTimeList) => {
        console.table(
          liveTimeList.map((e) => ({
            id: e.id,
            isSecretly: e.isSecretly,
            startedAt: dateTimeToShortFormat(e.startedAt),
            finishedAt: dateTimeToShortFormat(e.finishedAt),
          }))
        );

        setLiveTimeList(liveTimeList);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveStyle, dateTime]);

  return (
    <FullHeightContentCard
      title="自身の配信時間（通常配信/おしのび配信）"
      helpMessage="指定した配信形態/日付の自身の配信時間です。"
      controlPanel={
        <Box display="flex">
          <LiveStylePicker onChange={onLiveStyleChanged} disabled={loading} />
          <DatePicker
            label="日付"
            disabled={loading}
            onChange={onDateChanged}
          />
        </Box>
      }
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <LiverLiveTimeTableView liveTimeList={liveTimeList} />
        )
      }
    />
  );
}
