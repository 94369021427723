import { Tabs as TabsImpl, TabsProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  indicator: {
    background: 'none',
  },
}));

/**
 * タブメニュー
 */
export function Tabs(props: TabsProps): JSX.Element {
  const classes = useStyles();
  return (
    <TabsImpl
      {...props}
      classes={{
        indicator: classes.indicator,
      }}
      variant="scrollable"
      scrollButtons="auto"
    />
  );
}
