import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import HelpIconButton from 'src/component/common/HelpIconButton';
import Logo from 'src/component/common/Logo';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  cardContent: {
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
}));

type Props = {
  title: string;
  helpMessage: string;
  controlPanel: JSX.Element;
  notification?: string;
  content: JSX.Element;
};

export default function ContentCard(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={props.title}
        className={classes.cardHeader}
        action={<HelpIconButton message={props.helpMessage} />}
        titleTypographyProps={{ variant: 'h2' }}
      />
      <CardContent className={classes.cardContent}>
        <Box pb={2}>
          <Box display="flex" width={1}>
            <Box flexGrow={1}>{props.controlPanel}</Box>
            <Box display="flex" alignItems="center">
              <Box pr={2}>
                <Logo />
              </Box>
            </Box>
          </Box>
          {props.notification && (
            <Box color="notification.main" mx={2} lineHeight="0.8">
              <Typography variant="caption">{props.notification}</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="center" mb={1} p={1}>
          {props.content}
        </Box>
      </CardContent>
    </Card>
  );
}
