import { AffiliationLiverLiveTimeRank, Rank } from 'functions/src/model/rank';

/**
 * ランク種別
 */
export const rankType = {
  S6: 'S6' as Rank,
  S5: 'S5' as Rank,
  S4: 'S4' as Rank,
  S3: 'S3' as Rank,
  S2: 'S2' as Rank,
  S1: 'S1' as Rank,
  A3: 'A3' as Rank,
  A2: 'A2' as Rank,
  A1: 'A1' as Rank,
  B3: 'B3' as Rank,
  B2: 'B2' as Rank,
  B1: 'B1' as Rank,
  C3: 'C3' as Rank,
  C2: 'C2' as Rank,
  C1: 'C1' as Rank,
  D3: 'D3' as Rank,
  D2: 'D2' as Rank,
  D1: 'D1' as Rank,
  E1: 'E1' as Rank,
};

/**
 * ランク種別を降順に並べたものを返す
 * @returns ランク種別を降順に並べたもの
 */
export function rankTypeSequence(): Rank[] {
  return Object.entries(rankType)
    .sort(([_lhs, lhsValue], [_rhs, rhsValue]) => {
      const lhsCategory = lhsValue.charCodeAt(0) % 'S'.charCodeAt(0);
      const rhsCategory = rhsValue.charCodeAt(0) % 'S'.charCodeAt(0);

      if (lhsCategory === rhsCategory) {
        return Number(rhsValue.charAt(1)) - Number(lhsValue.charAt(1));
      } else {
        return lhsCategory - rhsCategory;
      }
    })
    .map(([_, v]) => v as Rank);
}

/**
 * 事務所所属ライバーの配信時間の表におけるライバーランク種別
 */
export const affiliationLiverLiveTimeRankType = {
  SA: 'S/A' as AffiliationLiverLiveTimeRank,
  B3: 'B3' as AffiliationLiverLiveTimeRank,
  B2: 'B2' as AffiliationLiverLiveTimeRank,
  B1: 'B1' as AffiliationLiverLiveTimeRank,
  C3: 'C3' as AffiliationLiverLiveTimeRank,
  C2: 'C2' as AffiliationLiverLiveTimeRank,
  C1: 'C1' as AffiliationLiverLiveTimeRank,
  D3: 'D3' as AffiliationLiverLiveTimeRank,
  D2: 'D2' as AffiliationLiverLiveTimeRank,
  D1: 'D1' as AffiliationLiverLiveTimeRank,
};

/**
 * 事務所所属ライバーの配信時間の表におけるライバーランク種別を降順に並べたものを返す
 * @returns 事務所所属ライバーの配信時間の表におけるライバーランク種別を降順に並べたもの
 */
export function affiliationLiverLiveTimeRankTypeSequence(): AffiliationLiverLiveTimeRank[] {
  const part = Object.entries(affiliationLiverLiveTimeRankType)
    .filter(([_, label]) => label !== 'S/A')
    .sort(([_lhs, lhsValue], [_rhs, rhsValue]) => {
      const lhsCategory = lhsValue.charCodeAt(0);
      const rhsCategory = rhsValue.charCodeAt(0);

      if (lhsCategory === rhsCategory) {
        return Number(rhsValue.charAt(1)) - Number(lhsValue.charAt(1));
      } else {
        return lhsCategory - rhsCategory;
      }
    })
    .map(([_, v]) => v as AffiliationLiverLiveTimeRank);

  return ['S/A', ...part];
}
