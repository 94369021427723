import { Box, Paper, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { yellow } from '@material-ui/core/colors';
import { loadCSS } from 'fg-loadcss';
import React from 'react';
import { TopFunRanking } from 'src/model/topFunRanking';

type Props = {
  topFunRanking: TopFunRanking;
};

/**
 * トップファンランキングカード
 */
export default function TopFunRankingCard(props: Props): JSX.Element {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      // @ts-ignore
      document.querySelector('#font-awesome-css')
    );

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      node.parentNode!.removeChild(node);
    };
  }, []);

  return (
    <Box width={1}>
      <Paper elevation={3}>
        <Box display="flex" width={1}>
          <Box display="flex" alignItems="center" width={20} mx={2}>
            <Box width={1}>
              <Typography variant="h2" align="center">
                {props.topFunRanking.rankOrder}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" my={1}>
            <img
              src={props.topFunRanking.imageUrl}
              alt="リスナーサムネイル"
              width={80}
            />
          </Box>
          <Box width={1} height={88}>
            <Box height={48} overflow="hidden" px={4} pt={4}>
              <Typography variant="body1" style={{ lineHeight: 1 }}>
                {props.topFunRanking.name}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              height={40}
              pr={4}
              pb={3}>
              <Icon className="fas fa-coins" style={{ color: yellow[600] }} />
              <Box display="flex" alignItems="flex-end" pl={2}>
                <Typography variant="h1">{props.topFunRanking.coin}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
