import { BorderTableTime } from 'functions/src/model/borderTableTime';

export type BorderTableTypeEntry = {
  time: BorderTableTime;
  label: string;
};

/**
 * ボーダーテーブル種別
 */
export const borderTableType = {
  border22: {
    time: 22,
    label: '22時',
  } as BorderTableTypeEntry,
  border24: {
    time: 24,
    label: '24時',
  } as BorderTableTypeEntry,
    border13: {
    time: 13,
    label: '13時',
  } as BorderTableTypeEntry,
};

/**
 * ボーダーテーブル種別を並べたものを返す
 * @returns ボーダーテーブル種別を並べたもの
 */
export function borderTableTypeSequence(): BorderTableTypeEntry[] {
  return Object.entries(borderTableType)
    .sort(([_lhs, lhsValue], [_rhs, rhsValue]) => {
      return lhsValue.time + rhsValue.time;
    })
    .map(([_, v]) => v);
}
