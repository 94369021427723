import SelectBox from 'src/component/common/SelectBox';
import { liveStyleType } from 'src/constant/liveStyleType';

type Props = {
  defaultSelectedIndex?: number | null;
  onChange: (liveStyle: number | null) => void;
  disabled?: boolean;
};

/**
 * 配信形態ピッカー
 */
export function LiveStylePicker(props: Props): JSX.Element {
  return (
    <SelectBox<number>
      id="live-style"
      label="配信形態"
      width={32}
      menuItemEntryList={liveStyleType}
      emptyItemLabel="指定しない"
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );
}
