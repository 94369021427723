import { DateTime } from 'luxon';
import React, { useState } from 'react';
import DatePicker from 'src/component/common/picker/DatePicker';

type Props = {
  disabled?: boolean;
  defaultBeginValue?: DateTime | null;
  defaultEndValue?: DateTime | null;
  onChange?: (begin: DateTime | null, end: DateTime | null) => void;
};

DateRangePicker.defaultProps = {
  disabled: false,
};

/**
 * 期間ピッカー
 */
export default function DateRangePicker(props: Props): JSX.Element {
  const [begin, setBegin] = useState<DateTime | null>(
    props.defaultBeginValue || null
  );
  const [end, setEnd] = useState<DateTime | null>(
    props.defaultEndValue || null
  );

  function onBeginChanged(dateTime: DateTime | null) {
    props.onChange && props.onChange(dateTime, end);
    setBegin(dateTime);
  }

  function onEndChanged(dateTime: DateTime | null) {
    props.onChange && props.onChange(begin, dateTime);
    setEnd(dateTime);
  }

  return (
    <>
      <DatePicker
        label="開始日"
        disabled={props.disabled}
        defaultValue={props.defaultBeginValue}
        max={end}
        onChange={onBeginChanged}
      />
      <DatePicker
        label="終了日"
        disabled={props.disabled}
        defaultValue={props.defaultEndValue}
        min={begin}
        onChange={onEndChanged}
      />
    </>
  );
}
