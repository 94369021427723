import icon from 'src/asset/logo.jpg';

type Props = {
  width?: number;
  height?: number;
};

Logo.defaultProps = {
  width: 42,
  height: (42 * 195) / 666,
};

/**
 * ロゴ
 */
export default function Logo(props: Props): JSX.Element {
  return (
    <img src={icon} alt="UPSTAR" width={props.width} height={props.height} />
  );
}
