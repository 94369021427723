import { Box } from '@material-ui/core';
import { AffiliationLiverLiveTimeRank, Rank } from 'functions/src/model/rank';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import FullHeightContentCard from 'src/component/common/layout/FullHeightContentCard';
import { AffiliationLiverLiveTimeRankPicker } from 'src/component/common/picker/AffiliationLiverLiveTimeRankPicker';
import MonthPicker from 'src/component/common/picker/MonthPicker';
import AffiliationLiverLiveTimeTableView from 'src/component/screen/affiliationliverlivetimetable/AffiliationLiverLiveTimeTableView';
import { AffiliationLiverLiveTime } from 'src/model/affiliationLiverLiveTime';
import { liveRepository } from 'src/model/repository/liveRepository';
import { secondsToTimeString } from 'src/util/dateTime';
import { getNextRank, rankToAffiliationLiverLiveTimeRank } from 'src/util/rank';

type Props = {
  liverId?: number;
  rank?: Rank;
};

export default function AffiliationLiverLiveTimeTableCard(
  props: Props
): JSX.Element {
  const defaultAffiliationLiverLiveTimeRank = props.rank
    ? rankToAffiliationLiverLiveTimeRank(getNextRank(props.rank))
    : null;

  const [dateTime, setDateTime] = useState<DateTime | null>(null);

  const [
    affiliationLiverLiveTimeRank,
    setAffiliationLiverLiveTimeRank,
  ] = useState<AffiliationLiverLiveTimeRank | null>(
    defaultAffiliationLiverLiveTimeRank
  );

  const [loading, setLoading] = useState(false);

  const [
    affiliationLiverLiveTimeList,
    setAffiliationLiverLiveTimeList,
  ] = useState<AffiliationLiverLiveTime[]>([]);

  function onMonthChanged(dateTime: DateTime | null) {
    setDateTime(dateTime);
  }

  function onRankChanged(rank: AffiliationLiverLiveTimeRank | null) {
    setAffiliationLiverLiveTimeRank(rank);
  }

  useEffect(() => {
    setAffiliationLiverLiveTimeList([]);
    setLoading(true);

    liveRepository
      .fetchAffiliationLiverLiveTime(
        dateTime?.year,
        dateTime?.month,
        affiliationLiverLiveTimeRank
      )
      .then((list) => {
        console.table(
          list.map((e) => ({
            ...e,
            averageLiveTime: secondsToTimeString(e.averageLiveTimeSeconds),
          }))
        );
        setAffiliationLiverLiveTimeList(list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateTime, affiliationLiverLiveTimeRank]);

  return (
    <FullHeightContentCard
      title="事務所所属ライバーの平均配信時間"
      helpMessage="指定した月/ランクの事務所所属ライバーの週毎の平均配信時間です。ただし週の始めは月曜日です。"
      controlPanel={
        <Box display="flex">
          <MonthPicker
            label="月"
            disabled={loading}
            onChange={onMonthChanged}
          />
          <AffiliationLiverLiveTimeRankPicker
            onChange={onRankChanged}
            defaultValue={defaultAffiliationLiverLiveTimeRank}
            disabled={loading}
            allowUnselected={true}
          />
        </Box>
      }
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <AffiliationLiverLiveTimeTableView
            affiliationLiverLiveTimeList={affiliationLiverLiveTimeList}
          />
        )
      }
    />
  );
}
