export type BorderTypeEntry = {
  name: string;
  label: string;
};

/**
 * ボーダー種別
 */
export const borderType = {
  top: {
    index: 1,
    name: 'borderTop',
    label: '+2',
  },
  upper: {
    index: 2,
    name: 'borderUpper',
    label: '+1',
  },
  normal: {
    index: 3,
    name: 'borderNormal',
    label: '±0',
  },
  /*
  lower: {
    index: 4,
    name: 'borderLower',
    label: '-1',
  },
  */
};

/**
 * ボーダー種別をindexでソートしたものを返す
 * @returns ボーダー種別をindexでソートしたもの
 */
export function borderTypeSequence(): BorderTypeEntry[] {
  return Object.entries(borderType)
    .map(([_, v]) => v)
    .sort((lhs, rhs) => lhs.index - rhs.index)
    .map((v) => ({ name: v.name, label: v.label }));
}
