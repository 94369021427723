/**
 * 曜日文字
 */
export const dayOfWeekSymbol = {
  1: '日',
  2: '月',
  3: '火',
  4: '水',
  5: '木',
  6: '金',
  7: '土',
};
