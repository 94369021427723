/**
 * ライブ形態種別
 */
export const liveStyleType = [
  {
    label: '通常配信',
    value: 0,
  },
  {
    label: 'おしのび配信',
    value: 1,
  },
];
