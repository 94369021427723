import { IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useState } from 'react';
import HelpDialog from 'src/component/common/dialog/HelpDialog';

type Props = {
  message: string;
};

/**
 * ヘルプアイコンボタン
 */
export default function HelpIconButton(props: Props): JSX.Element {
  const [isDialogOpened, setDialogOpened] = useState(false);

  function onDialogClosed() {
    setDialogOpened(false);
  }

  function onClicked() {
    setDialogOpened(true);
  }

  return (
    <>
      <IconButton aria-label="help" onClick={onClicked}>
        <HelpIcon />
      </IconButton>
      <HelpDialog
        open={isDialogOpened}
        message={props.message}
        onClosed={onDialogClosed}
      />
    </>
  );
}
