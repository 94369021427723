import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  outlinedInput: {
    width: theme.spacing(30),
    height: theme.spacing(9),
    alignItems: 'baseline',
  },
  inputMarginDense: {
    paddingBottom: '16px',
  },
}));

type Props = {
  label: string;
  disabled?: boolean;
  defaultValue?: DateTime | null;
  onChange?: (dateTime: DateTime | null) => void;
};

MonthPicker.defaultProps = {
  disabled: false,
};

/**
 * 月ピッカー
 */
export default function MonthPicker(props: Props): JSX.Element {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-types
  function onChanged(event: Object) {
    // @ts-ignore
    const dateStr = event.target.value;

    props.onChange &&
      props.onChange(dateStr ? DateTime.fromISO(dateStr) : null);
  }

  return (
    <Box display="inline">
      <FormControl
        className={classes.container}
        margin="dense"
        disabled={props.disabled}>
        <InputLabel id="month-label" variant="outlined" htmlFor="month">
          {props.label}
        </InputLabel>
        <OutlinedInput
          id="month"
          className={classes.outlinedInput}
          classes={{
            inputMarginDense: classes.inputMarginDense,
          }}
          type="month"
          defaultValue={
            props.defaultValue ? props.defaultValue.toFormat('YYYY-MM') : ''
          }
          label="month-label"
          onChange={onChanged}
        />
      </FormControl>
    </Box>
  );
}
