/**
 * 数値を単位が万のものに変換する
 *
 * @param number - 数値
 * @returns 単位が万の数値
 */
export function tenThousandNotation(number: number): number {
  return Math.round(number / 10000);
}

/**
 * 数値を単位が千のものに変換する
 *
 * @param number - 数値
 * @returns 単位が千の数値
 */
export function thousandNotation(number: number): number {
  return Math.round(number / 1000);
}
