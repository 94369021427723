import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import TextInput from 'src/component/common/TextInput';
import FullHeightContentCard from 'src/component/common/layout/FullHeightContentCard';
import DateRangePicker from 'src/component/common/picker/DateRangePicker';
import EventBorderListView from 'src/component/screen/eventborderlist/EventBorderListView';
import { EventBorder } from 'src/model/EventBorder';
import { eventLastDayBorderRepository } from 'src/model/repository/eventLastDayBorderRepository';

export default function EventBorderListCard(): JSX.Element {
  const [begin, setBegin] = useState<DateTime | null>(null);
  const [end, setEnd] = useState<DateTime | null>(null);
  const [eventName, setEventName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [eventBorderList, setEventBorderList] = useState<EventBorder[]>([]);

  function onDateRangeChanged(begin: DateTime | null, end: DateTime | null) {
    setBegin(begin);
    setEnd(end);
  }

  function onEventNameChanged(text: string | null) {
    setEventName(text);
  }

  useEffect(() => {
    setEventBorderList([]);
    setLoading(true);

    eventLastDayBorderRepository
      .fetchEventLastDayBorder(begin, end, eventName?.split(/\s+/))
      .then((list) => {
        console.table(
          list.map((e) => ({
            ...e,
            startedDate: e.startedDate.toISODate(),
            endedDate: e.endedDate.toISODate(),
          }))
        );

        setEventBorderList(list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [begin, end, eventName]);

  return (
    <FullHeightContentCard
      title="イベント最終日の応援PTボーダー"
      helpMessage="指定した期間/イベント名のイベント一覧です。"
      controlPanel={
        <>
          <Box display="flex">
            <DateRangePicker onChange={onDateRangeChanged} disabled={loading} />
          </Box>
          <TextInput
            id="event-name"
            label="イベント名"
            width={310}
            placeholder="スペースで区切ると複数のキーワードが使えます"
            onChange={onEventNameChanged}
            disabled={loading}
          />
        </>
      }
      notification="毎月10日前後に前月のデータが反映されます。"
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <EventBorderListView eventBorderList={eventBorderList} />
        )
      }
    />
  );
}
