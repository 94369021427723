import { makeStyles, Tab as TabImpl, TabProps } from '@material-ui/core';
import React from 'react';
import { Label } from 'src/component/common/tab/Label';

type Props = {
  index: number;
  label: string;
  selected: boolean;
} & TabProps;

const useStyles = makeStyles((theme) => ({
  tab: {
    textTransform: 'none',
    padding: theme.spacing(1),
  },
}));

/**
 * タブ
 */
export function Tab(props: Props): JSX.Element {
  const classes = useStyles();

  const propsImpl = {
    ...props,
    label: <Label text={props.label} selected={props.selected} />,
  };

  return (
    <TabImpl
      id={`scrollable-auto-tab-${props.index}`}
      className={classes.tab}
      aria-controls={`scrollable-auto-tab-panel-${props.index}`}
      {...propsImpl}
    />
  );
}
