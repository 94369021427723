import { Box } from '@material-ui/core';
import { Rank } from 'functions/src/model/rank';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import ContentCard from 'src/component/common/layout/ContentCard';
import { RankPicker } from 'src/component/common/picker/RankPicker';
import DayOfWeekBorderGraphView from 'src/component/screen/dayofweekbordergraph/DayOfWeekBorderGraphView';
import { BorderTableTypeEntry } from 'src/constant/borderTableType';
import { dayOfWeekSymbol } from 'src/constant/dayOfWeekSymbol';
import { rankType } from 'src/constant/rankType';
import { Border } from 'src/model/border';
import { borderRepository } from 'src/model/repository/borderRepository';
import { lastMonth, normalizedDayOfWeek } from 'src/util/dateTime';

type Props = {
  table: BorderTableTypeEntry;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createLogData(borderList: Border[]): any {
  return [...Array(7)]
    .map((_, i) => ({
      // @ts-ignore
      dayOfWeek: dayOfWeekSymbol[i + 1],
      borderList: borderList.filter(
        (e: Border) => i + 1 === normalizedDayOfWeek(DateTime.fromISO(e.date))
      ),
    }))
    .flatMap((e) =>
      e.borderList.map((border: Border) => ({
        dayOfWeek: e.dayOfWeek,
        ...border,
      }))
    );
}

export default function DayOfWeekBorderGraphCard(props: Props): JSX.Element {
  const defaultRank = rankType.C1;
  const [rank, setRank] = useState<Rank>(defaultRank);
  const [loading, setLoading] = useState(false);
  const [borderList, setBorderList] = useState<Border[]>([]);

  function onRankChanged(rank: Rank | null) {
    rank && setRank(rank);
  }

  useEffect(() => {
    setBorderList([]);

    if (rank) {
      setLoading(true);

      borderRepository
        .fetchBorder(
          props.table.time,
          lastMonth().year,
          lastMonth().month,
          null,
          null,
          {
            rankList: [rank],
            dayOfWeekList: [],
          }
        )
        .then((borderList) => {
          console.table(createLogData(borderList));
          setBorderList(borderList);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rank]);

  return (
    <ContentCard
      title="前月の平均ボーダー"
      helpMessage="指定したランクの前月の曜日毎の平均ボーダーです。"
      controlPanel={
        <RankPicker
          disabled={loading}
          defaultValue={defaultRank}
          onChange={onRankChanged}
        />
      }
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <DayOfWeekBorderGraphView name={rank} borderList={borderList} />
        )
      }
    />
  );
}
