import { DateTime } from 'luxon';
import { firebase } from 'src/model/firebase';
import { Listener } from 'src/model/listener';
import { ListenerCoinChange } from 'src/model/listenerCoinChange';
import { TopFunRanking } from 'src/model/topFunRanking';

/**
 * トップファンリポジトリ
 */
class TopFunRepository {
  /**
   * トップファンのランキングを取得する
   *
   * @param liverId - ライバーID
   * @param date - 日付
   * @returns [Promise]
   */
  fetchTopFunRanking(
    liverId: number,
    date: DateTime
  ): Promise<TopFunRanking[]> {
    return firebase
      .functions()
      .httpsCallable('fetchTopFunRanking')({
        liverId: liverId,
        date: date && {
          year: date.year,
          month: date.month,
          day: date.day,
        },
      })
      .then((result) =>
        // @ts-ignore
        result.data[0].map((e) => ({
          rankOrder: e.rankOrder,
          imageUrl: e.imageUrl,
          name: e.name,
          coin: e.coin,
        }))
      );
  }

  /**
   * 消費コインが上位のリスナーを取得する
   *
   * @param liverId - ライバーID
   * @param limit - 制限数
   * @returns [Promise]
   */
  fetchListener(liverId: number, limit: number): Promise<Listener[]> {
    return firebase
      .functions()
      .httpsCallable('fetchListener')({
        liverId: liverId,
        limit: limit,
      })
      .then(
        // @ts-ignore
        (result) => result.data[0]
      );
  }

  /**
   * リスナーのコイン推移を取得する
   *
   * @param liverId - ライバーID
   * @param begin - 開始日付
   * @param end - 終了日付
   * @param listenerIdList - リスナーIDリスト
   * @returns [Promise]
   */
  fetchListenerCoinChanges(
    liverId: number,
    begin: DateTime | null,
    end: DateTime | null,
    listenerIdList: number[] | null
  ): Promise<ListenerCoinChange[]> {
    return firebase
      .functions()
      .httpsCallable('fetchListenerCoinChanges')({
        liverId: liverId,
        begin: begin && {
          year: begin.year,
          month: begin.month,
          day: begin.day,
        },
        end: end && {
          year: end.year,
          month: end.month,
          day: end.day,
        },
        listenerIdList: listenerIdList,
      })
      .then((result) => {
        // @ts-ignore
        return result.data[0].map((e) => ({
          date: DateTime.fromISO(e.date.value),
          id: e.id,
          coin: e.coin,
        }));
      });
  }
}

export const topFunRepository = new TopFunRepository();
