import { Box } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import FullHeightContentCard from 'src/component/common/layout/FullHeightContentCard';
import DateRangePicker from 'src/component/common/picker/DateRangePicker';
import { ListenerPicker } from 'src/component/common/picker/ListenerPicker';
import ListenerCoinChangesGraphView, {
  ColorMap,
  Entry,
} from 'src/component/screen/listenercoinchangesgraph/ListenerCoinChangesGraphView';
import { Listener } from 'src/model/listener';
import { ListenerCoinChange } from 'src/model/listenerCoinChange';
import { topFunRepository } from 'src/model/repository/topFunRepository';
import { yesterday } from 'src/util/dateTime';

const listenerColor = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FF00FF',
  '#00AAFF',
  '#990000',
  '#009900',
  '#000099',
  '#999900',
  '#8800AA',
];

type Props = {
  liverId: number;
};

/**
 * リスナーのコイン推移リストを補正する
 *
 * 開始日から終了日の間で、リスナーのデータがない日は消費コインが0のデータを加える。
 *
 * @param listenerCoinChangeList - リスナーのコイン推移リスト
 * @param begin - 開始日
 * @param end - 終了日
 * @param listenerIdList - リスナーIDリスト
 * @returns 補正したリスナーのコイン推移リスト
 */
function correctListenerCoinChanges(
  listenerCoinChangeList: ListenerCoinChange[],
  begin: DateTime,
  end: DateTime,
  listenerIdList: number[]
): ListenerCoinChange[] {
  const period = Interval.fromDateTimes(begin, end);
  const dst = [];

  for (let date = begin; period.contains(date); date = date.plus({ days: 1 })) {
    for (const listenerId of listenerIdList) {
      const e = listenerCoinChangeList.find(
        (e) => e.date.toISODate() === date.toISODate() && e.id === listenerId
      );

      if (e) {
        dst.push(e);
      } else {
        dst.push({
          date: date,
          id: listenerId,
          coin: 0,
        });
      }
    }
  }

  return dst.map((e) => ({
    date: e.date,
    id: e.id,
    // @ts-ignore
    coin: e.coin,
  }));
}

export default function ListenerCoinChangesGraphCard(
  props: Props
): JSX.Element {
  const defaultBegin = yesterday().minus({ day: 6 });
  const defaultEnd = yesterday();
  const [begin, setBegin] = useState<DateTime | null>(defaultBegin);
  const [end, setEnd] = useState<DateTime | null>(defaultEnd);
  const [listenerIdList, setListenerIdList] = useState<Array<number>>([]);
  const [listenerList, setListenerList] = useState<Listener[] | null>(null);
  const [colorMap, setColorMap] = useState<ColorMap>(new Map());
  const [entryList, setEntryList] = useState<Entry[]>([]);
  const [loading, setLoading] = useState(false);

  function onDateRangeChanged(begin: DateTime | null, end: DateTime | null) {
    setBegin(begin);
    setEnd(end);
  }

  function onListenerChanged(listenerIdList: Array<number>) {
    setListenerIdList(listenerIdList);
  }

  function onListenerLoaded(listenerList: Listener[]) {
    setColorMap(new Map(listenerList.map((e, i) => [e.id, listenerColor[i]])));
    setListenerList(listenerList);
  }

  useEffect(() => {
    if (listenerList) {
      setEntryList([]);
      setLoading(true);

      topFunRepository
        .fetchListenerCoinChanges(
          props.liverId,
          begin,
          end,
          listenerIdList.length ? listenerIdList : listenerList.map((e) => e.id)
        )
        .then((listenerCoinChangeList) => {
          if (listenerCoinChangeList.length > 0) {
            console.table(
              listenerCoinChangeList.map((e) => ({
                date: e.date.toISODate(),
                id: e.id,
                // @ts-ignore
                name: listenerList.find((v) => v.id === e.id).name,
                coin: e.coin,
              }))
            );

            const correctedListenerCoinChanges = correctListenerCoinChanges(
              listenerCoinChangeList,
              begin ||
                listenerCoinChangeList.reduce((dst, next) =>
                  next.date < dst.date ? next : dst
                ).date,
              end ||
                listenerCoinChangeList.reduce((dst, next) =>
                  dst.date < next.date ? next : dst
                ).date,
              listenerIdList.length
                ? listenerIdList
                : listenerList.map((e) => e.id)
            );

            setEntryList(
              correctedListenerCoinChanges.map((e) => {
                const listener = listenerList.find(
                  (listener) => listener.id === e.id
                );

                return {
                  date: e.date,
                  id: e.id,
                  // @ts-ignore
                  name: listener.name,
                  // @ts-ignore
                  imageUrl: listener.imageUrl,
                  coin: e.coin,
                };
              })
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begin, end, listenerIdList, listenerList]);

  return (
    <FullHeightContentCard
      title="リスナーのコイン推移"
      helpMessage="指定した期間/リスナーの消費コインの推移です。"
      controlPanel={
        <>
          <Box display="flex">
            <DateRangePicker
              defaultBeginValue={defaultBegin}
              defaultEndValue={defaultEnd}
              onChange={onDateRangeChanged}
              disabled={loading || listenerList?.length === 0}
            />
            <ListenerPicker
              liverId={props.liverId}
              limit={10}
              onChange={onListenerChanged}
              onListenerLoaded={onListenerLoaded}
              disabled={loading || listenerList?.length === 0}
            />
          </Box>
        </>
      }
      notification="Pocochaアプリで事務所への情報提供を承諾しているリスナーさん上位10名が表示されます。"
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <ListenerCoinChangesGraphView
            entryList={entryList}
            colorMap={colorMap}
          />
        )
      }
    />
  );
}
