import { BorderFilter } from 'functions/src/model/borderFilter';
import { BorderTableTime } from 'functions/src/model/borderTableTime';
import { DateTime } from 'luxon';
import { Border } from 'src/model/border';
import { firebase } from 'src/model/firebase';

/**
 * ボーダーリポジトリ
 */
class BorderRepository {
  /**
   * 固定枠のボーダーを取得する
   *
   * 年月週日などを指定してボーダーを取得する。
   * [week]と[day]の指定は排他になる。
   * 年の最初の日曜日より前の日は第0週になる。
   * [month]と[week]を同時に指定した場合、[week]は1以上でなくてはならない。
   * また、その場合[week]は、その月の週番号と見なされる。
   *
   * @param borderTableTime - ボーダーテーブルの時間
   * @param year - 年
   * @param month - 月
   * @param week - 週番号
   * @param day - 日
   * @param filter - ボーダー抽出フィルタ
   * @returns [Promise]
   */
  fetchBorder(
    borderTableTime: BorderTableTime,
    year: number | null = null,
    month: number | null = null,
    week: number | null = null,
    day: number | null = null,
    filter: BorderFilter | null = null
  ): Promise<Border[]> {
    return firebase
      .functions()
      .httpsCallable('fetchBorder')({
        borderTableTime: borderTableTime,
        year: year,
        month: month,
        day: day,
        week: week,
        filter: filter,
      })
      .then((result) => result.data[0]);
  }

  /**
   * 期間ボーダーを取得する
   *
   * @param borderTableTime - ボーダーテーブルの時間
   * @param begin - 開始日
   * @param end - 終了日
   * @param filter - ボーダー抽出フィルタ
   * @returns [Promise]
   */
  fetchBorderByPeriod(
    borderTableTime: BorderTableTime,
    begin: DateTime | null,
    end: DateTime | null,
    filter: BorderFilter | null
  ): Promise<Border[]> {
    return firebase
      .functions()
      .httpsCallable('fetchBorderByPeriod')({
        borderTableTime: borderTableTime,
        begin: begin && {
          year: begin.year,
          month: begin.month,
          day: begin.day,
        },
        end: end && {
          year: end.year,
          month: end.month,
          day: end.day,
        },
        filter: filter,
      })
      .then((result) => result.data[0]);
  }
}

export const borderRepository = new BorderRepository();
