import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import ContentCard from 'src/component/common/layout/ContentCard';
import DatePicker from 'src/component/common/picker/DatePicker';
import BorderTableView from 'src/component/screen/bordertable/BorderTableView';
import { BorderTableTypeEntry } from 'src/constant/borderTableType';
import { Border } from 'src/model/border';
import { borderRepository } from 'src/model/repository/borderRepository';
import { yesterday, dayBeforeYesterday } from 'src/util/dateTime';

type Props = {
  table: BorderTableTypeEntry;
};

export default function BorderTableCard(props: Props): JSX.Element {
  const defaultDateTime = props.table.time === 13 ? dayBeforeYesterday() : yesterday();
  const [dateTime, setDateTime] = useState<DateTime | null>(defaultDateTime);
  const [loading, setLoading] = useState(false);
  const [borderList, setBorderList] = useState<Border[]>([]);

  function onDateChanged(dateTime: DateTime | null) {
    setDateTime(dateTime);
  }

  useEffect(() => {
    setBorderList([]);

    if (dateTime) {
      setLoading(true);

      borderRepository
        .fetchBorder(
          props.table.time,
          dateTime.year,
          dateTime.month,
          null,
          dateTime.day
        )
        .then((borderList) => {
          console.table(borderList);
          setBorderList(borderList);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime]);

  return (
    <ContentCard
      title="ランク別ボーダー"
      helpMessage="指定した日付のランク別のボーダーです。5/3以前の±0は未取得です。"
      controlPanel={
        <DatePicker
          label="日付"
          disabled={loading}
          defaultValue={defaultDateTime}
          onChange={onDateChanged}
        />
      }
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <BorderTableView borderList={borderList} />
        )
      }
    />
  );
}
