import { Box } from '@material-ui/core';
import { Rank } from 'functions/src/model/rank';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import ContentCard from 'src/component/common/layout/ContentCard';
import DateRangePicker from 'src/component/common/picker/DateRangePicker';
import { RankPicker } from 'src/component/common/picker/RankPicker';
import PeriodBorderGraphView from 'src/component/screen/periodbordergraph/PeriodBorderGraphView';
import { BorderTableTypeEntry } from 'src/constant/borderTableType';
import { rankType } from 'src/constant/rankType';
import { Border } from 'src/model/border';
import { borderRepository } from 'src/model/repository/borderRepository';
import { yesterday } from 'src/util/dateTime';

type Props = {
  table: BorderTableTypeEntry;
};

export default function PeriodBorderGraphCard(props: Props): JSX.Element {
  const defaultRank = rankType.C1;
  const defaultBegin = yesterday().minus({ day: 6 });
  const defaultEnd = yesterday();
  const [rank, setRank] = useState<Rank>(defaultRank);
  const [begin, setBegin] = useState<DateTime | null>(defaultBegin);
  const [end, setEnd] = useState<DateTime | null>(defaultEnd);
  const [loading, setLoading] = useState(false);
  const [borderList, setBorderList] = useState<Border[]>([]);

  function onRankChanged(rank: Rank | null) {
    rank && setRank(rank);
  }

  function onChanged(begin: DateTime | null, end: DateTime | null) {
    setBegin(begin);
    setEnd(end);
  }

  useEffect(() => {
    setBorderList([]);

    if (rank) {
      setLoading(true);

      borderRepository
        .fetchBorderByPeriod(props.table.time, begin, end, {
          rankList: [rank],
          dayOfWeekList: [],
        })
        .then((borderList) => {
          console.table(borderList);
          setBorderList(borderList);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rank, begin, end]);

  return (
    <ContentCard
      title="ボーダー推移"
      helpMessage="指定したランク/期間のボーダー推移です。"
      controlPanel={
        <Box display="flex">
          <RankPicker
            disabled={loading}
            defaultValue={defaultRank}
            onChange={onRankChanged}
          />
          <DateRangePicker
            disabled={loading}
            defaultBeginValue={defaultBegin}
            defaultEndValue={defaultEnd}
            onChange={onChanged}
          />
        </Box>
      }
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <PeriodBorderGraphView borderList={borderList} />
        )
      }
    />
  );
}
