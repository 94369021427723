import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import HelpIconButton from 'src/component/common/HelpIconButton';
import Logo from 'src/component/common/Logo';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    height: '100%',
  },
  cardHeader: {
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingRight: theme.spacing(1),
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
  },
}));

type Props = {
  title: string;
  helpMessage: string;
  controlPanel: JSX.Element;
  notification?: string;
  content: JSX.Element;
};

/**
 * 高さが最大となるコンテンツカード
 */
export default function FullHeightContentCard(props: Props): JSX.Element {
  const classes = useStyles();
  const card = useRef(null);
  const cardHeader = useRef(null);
  const control = useRef(null);
  const [cardContentHeight, setCardContentHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setCardContentHeight(
      // @ts-ignore
      card.current.getBoundingClientRect().height -
        // @ts-ignore
        cardHeader.current.getBoundingClientRect().height
    );

    setContentHeight(
      // @ts-ignore
      card.current.getBoundingClientRect().height -
        // @ts-ignore
        cardHeader.current.getBoundingClientRect().height -
        // @ts-ignore
        control.current.getBoundingClientRect().height
    );
  }, [card, cardHeader, control, cardContentHeight, contentHeight]);

  return (
    <Box p={1} height={1}>
      <Card ref={card} className={classes.card}>
        <CardHeader
          ref={cardHeader}
          title={props.title}
          className={classes.cardHeader}
          action={<HelpIconButton message={props.helpMessage} />}
          titleTypographyProps={{ variant: 'h2' }}
        />
        <CardContent className={classes.cardContent}>
          <Box height={cardContentHeight}>
            <div ref={control}>
              <Box pb={2}>
                <Box display="flex" width={1}>
                  <Box flexGrow={1}>{props.controlPanel}</Box>
                  <Box display="flex" alignItems="center">
                    <Box pr={2}>
                      <Logo />
                    </Box>
                  </Box>
                </Box>
                {props.notification && (
                  <Box color="notification.main" mx={2} lineHeight="0.8">
                    <Typography variant="caption">
                      {props.notification}
                    </Typography>
                  </Box>
                )}
              </Box>
            </div>
            <Box display="flex" justifyContent="center" height={contentHeight}>
              {props.content}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
