import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

type Props = {
  text: string;
  selected: boolean;
};

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    borderWidth: (props: Props) => (props.selected ? 2 : 0),
    borderStyle: 'solid',
    borderRadius: 16,
  },
}));

/**
 * ラベル
 */
export function Label(props: Props): JSX.Element {
  const classes = useStyles(props);
  return <Box className={classes.label}>{props.text}</Box>;
}
