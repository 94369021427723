import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { EventBorder } from 'src/model/EventBorder';
import { dateTimeToLabel } from 'src/util/dateTime';

const useStyles = makeStyles((theme) => ({
  stageLabel: {
    padding: theme.spacing(1),
    borderRadius: 16,
    fontSize: 10,
    textAlign: 'center',
  },
  borderLabel: {
    width: 110,
    padding: theme.spacing(1),
    color: '#FFFFFF',
    borderRadius: 16,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

type Props = {
  eventBorder: EventBorder;
};

/**
 * イベント最終日の応援ptボーダーカード
 */
export default function EventBorderCard(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box width={1}>
      <Paper square elevation={3}>
        <Box width={1} p={4}>
          <Box display="flex">
            <Box>
              <Typography variant="caption">
                {dateTimeToLabel(props.eventBorder.startedDate)}
              </Typography>
            </Box>
            <Box mx={1}>〜</Box>
            <Box>
              <Typography variant="caption">
                {dateTimeToLabel(props.eventBorder.endedDate)}
              </Typography>
            </Box>
          </Box>
          <Box my={2} height={34} overflow="hidden">
            <Typography variant="h2">{props.eventBorder.eventName}</Typography>
          </Box>
          <Box my={1}>
            <Box display="flex">
              <Box className={classes.stageLabel} border={1}>
                ステージ
              </Box>
              <Box mx={2}>
                <Typography variant="caption">
                  {props.eventBorder.stageName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  {props.eventBorder.blockName}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Box display="flex">
              <Box className={classes.borderLabel} bgcolor="#FF0000">
                1位のボーダー
              </Box>
              <Box width={120} ml={4} textAlign="end">
                {props.eventBorder.topLine.toLocaleString()}
              </Box>
            </Box>
            <Box mt={1} display="flex">
              <Box className={classes.borderLabel} bgcolor="#0000FF">
                入賞者のボーダー
              </Box>
              <Box width={120} ml={4} textAlign="end">
                {props.eventBorder.border.toLocaleString()}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
