import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Container from 'src/component/common/layout/Container';
import {
  BorderTableTypeEntry,
  borderTableTypeSequence,
} from 'src/constant/borderTableType';

const useStyles = makeStyles((theme) => ({
  tableArea: {
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
  },
}));

type CardProps = {
  table: BorderTableTypeEntry;
};

type Props = {
  card: (props: CardProps) => JSX.Element;
};

/**
 * 全ボーダーテーブルの[ContentCard]を上から下へ並べたもの
 */
export default function BorderCardList(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {borderTableTypeSequence().map((entry) => (
        <Box key={entry.time} className={classes.tableArea}>
          <Box p={2} color="white" bgcolor="#555555">
            <Typography variant="h1">{entry.label}</Typography>
          </Box>
          <Container>{props.card({ table: entry })}</Container>
        </Box>
      ))}
    </>
  );
}
