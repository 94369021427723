import { AffiliationLiverLiveTimeRank, Rank } from 'functions/src/model/rank';
import { rankTypeSequence } from 'src/constant/rankType';

/**
 * ライバーランクを事務所所属ライバーの配信時間の表におけるライバーランクへ変換する
 *
 * @param rank - ライバーランク
 * @returns 事務所所属ライバーの配信時間の表におけるライバーランク
 */
export function rankToAffiliationLiverLiveTimeRank(
  rank: Rank
): AffiliationLiverLiveTimeRank | null {
  switch (rank.charAt(0).toUpperCase()) {
    case 'S':
    case 'A':
      return 'S/A';
    case 'E':
      return null;
    default:
      return rank as AffiliationLiverLiveTimeRank;
  }
}

/**
 * 一つ上のライバーランクを返す
 *
 * @param rank - ライバーランク
 * @returns 一つ上のライバーランク
 */
export function getNextRank(rank: Rank): Rank {
  const rankTypeList = rankTypeSequence();
  const i = rankTypeList.indexOf(rank);

  if (i > 0) {
    return rankTypeList[i - 1];
  } else {
    return rankTypeList[0];
  }
}
