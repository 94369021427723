import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectRoot: (props) => ({
    // @ts-ignore
    width: theme.spacing(props.width),
    height: theme.spacing(9),
  }),
}));

export type MenuItemEntry<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  id: string;
  label: string;
  width?: number;
  menuItemEntryList: MenuItemEntry<T>[];
  emptyItemLabel?: string | null;
  defaultSelectedIndex?: number | null;
  onChange: (value: T | null) => void;
  disabled?: boolean;
};

SelectBox.defaultProps = {
  disabled: false,
};

/**
 * セレクトボックス
 */
export default function SelectBox<T>(props: Props<T>): JSX.Element {
  const classes = useStyles(props);

  const isEmptyEnabled =
    props.emptyItemLabel != null && props.emptyItemLabel !== '';

  const defaultValue =
    props.defaultSelectedIndex != null
      ? props.menuItemEntryList[props.defaultSelectedIndex].value
      : isEmptyEnabled
      ? ''
      : null;

  // eslint-disable-next-line @typescript-eslint/ban-types
  function onChange(event: Object) {
    // @ts-ignore
    const value = event.target.value;
    props.onChange(value === 0 || value ? value : null);
  }

  return (
    <Box display="inline">
      <FormControl
        className={classes.container}
        margin="dense"
        disabled={props.disabled}>
        <InputLabel
          id={`${props.id}-select-label`}
          variant="outlined"
          htmlFor={`${props.id}-select`}>
          {props.label}
        </InputLabel>
        <Select
          id={`${props.id}-select`}
          className={classes.selectRoot}
          variant="outlined"
          defaultValue={defaultValue}
          displayEmpty={isEmptyEnabled}
          label={`${props.id}-select-label`}
          onChange={onChange}>
          {isEmptyEnabled && (
            <MenuItem value="">{props.emptyItemLabel}</MenuItem>
          )}
          {props.menuItemEntryList.map((e) => (
            // @ts-ignore
            <MenuItem key={e.value} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
