import ListView from 'src/component/common/ListView';
import TopFunRankingCard from 'src/component/screen/topfunrankingtable/TopFunRankingCard';
import { TopFunRanking } from 'src/model/topFunRanking';

type Props = {
  topFunRankingList: TopFunRanking[];
};

/**
 * トップファンランキングリスト
 */
export default function TopFunRankingListView(props: Props): JSX.Element {
  return (
    <ListView
      data={props.topFunRankingList}
      itemKey={(e) => e.name}
      itemContent={(e) => <TopFunRankingCard topFunRanking={e} />}
      itemSize={100}
    />
  );
}
