import { AppBar, Box, CssBaseline } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { use100vh } from 'react-div-100vh';
import { Tab, TabPanel, Tabs } from 'src/component/common/tab';
import {
  AffiliationLiverLiveTimeTableScreen,
  BorderTableScreen,
  DayOfWeekBorderGraphScreen,
  EventBorderListScreen,
  ListenerCoinChangesGraphScreen,
  LiverLiveTimeTableScreen,
  PeriodBorderGraphScreen,
  TopFunRankingListScreen,
} from 'src/component/screen';

type TabEntry = {
  name: string;
  label: string;
  content: JSX.Element;
};

function createTabList(liverId?: number): Array<TabEntry> {
  const tabList = [
    {
      name: 'border-table',
      label: 'ランク別ボーダー',
      content: <BorderTableScreen />,
    },
    {
      name: 'day-of-week-border-graph',
      label: '前月の平均ボーダー',
      content: <DayOfWeekBorderGraphScreen />,
    },
    {
      name: 'period-border-graph',
      label: 'ボーダー推移',
      content: <PeriodBorderGraphScreen />,
    },
    {
      name: 'affiliation-liver-live-time-table',
      label: '事務所所属ライバーの平均配信時間',
      content: <AffiliationLiverLiveTimeTableScreen liverId={liverId} />,
    },
    {
      name: 'event-border-list',
      label: 'イベント最終日の応援 PT ボーダー',
      content: <EventBorderListScreen />,
    },
  ];

  if (liverId) {
    tabList.push({
      name: 'liver-live-time-table',
      label: '配信時間',
      content: <LiverLiveTimeTableScreen liverId={liverId} />,
    });
    tabList.push({
      name: 'top-fun-ranking-table',
      label: 'トップファンランキング',
      content: <TopFunRankingListScreen liverId={liverId} />,
    });
    tabList.push({
      name: 'listener-coin-changes-graph',
      label: 'リスナーのコイン推移',
      content: <ListenerCoinChangesGraphScreen liverId={liverId} />,
    });
  }

  return tabList;
}

type Props = {
  liverId?: number;
};

export default function Home(props: Props): JSX.Element {
  const [cookies, setCookie] = useCookies(['tabIndex']);
  const tabList = createTabList(props.liverId);
  const previousTabIndex = cookies.tabIndex ? Number(cookies.tabIndex) : 0;

  const [tabIndex, setTabIndex] = useState(
    previousTabIndex < tabList.length ? previousTabIndex : 0
  );

  const height = use100vh() || 0;
  const appBar = useRef(null);
  const [appBarHeight, setAppBarHeight] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  function onChanged(_: Object, value: number) {
    setTabIndex(value);
    setCookie('tabIndex', value, { path: '/' });
  }

  useEffect(() => {
    setAppBarHeight(
      // @ts-ignore
      appBar.current.getBoundingClientRect().height
    );
  }, [appBar]);

  return (
    <>
      <CssBaseline />
      <AppBar ref={appBar} position="sticky" color="inherit">
        <Tabs
          value={tabIndex}
          // @ts-ignore
          onChange={onChanged}
          aria-label="図表タブ">
          {tabList.map(({ label }, i) => (
            <Tab
              key={label}
              index={i}
              label={label}
              selected={tabIndex === i}
            />
          ))}
        </Tabs>
      </AppBar>
      <Box height={height - appBarHeight}>
        {tabList.map(({ name, content }, i) => (
          <TabPanel key={name} index={i} value={tabIndex}>
            {content}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}
