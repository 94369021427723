import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableBodyHeadCell: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableBodyCell: {
    fontSize: 12,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

type Column<T> = {
  header: string;
  getValue: (e: T) => number | boolean | string | JSX.Element;
  isHead?: boolean;
} & TableCellProps;

type Props<T> = {
  columnList: Column<T>[];
  data: T[];
  ariaLabel: string;
};

export default function TableView<T>(props: Props<T>): JSX.Element {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label={props.ariaLabel}>
        <TableHead>
          <TableRow className={classes.tableRow}>
            {props.columnList.map((column) => (
              <TableCell
                key={column.header}
                className={classes.tableHeaderCell}
                width={column.width}
                align={column.align}>
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((record, i) => (
            <TableRow key={i} className={classes.tableRow}>
              {props.columnList.map((column) => {
                if (column?.isHead) {
                  return (
                    <TableCell
                      key={column.header}
                      className={classes.tableBodyHeadCell}
                      align={column.align}
                      component="th"
                      scope="row">
                      {column.getValue(record)}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={column.header}
                      className={classes.tableBodyCell}
                      align={column.align}>
                      {column.getValue(record)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
