import { useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Area, AreaChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';
import { LinearGradient } from 'src/component/common/Gradient';
import { borderTypeSequence } from 'src/constant/borderType';
import { Border } from 'src/model/border';
import { dateTimeToShortLabel } from 'src/util/dateTime';
import { tenThousandNotation } from 'src/util/number';

type Props = {
  borderList: Border[];
};

/**
 * 期間ボーダーグラフ
 *
 * ボーダーリストのボーダーのライバーランクは全て同じであることを要求する。
 */
export default function PeriodBorderGraphView(props: Props): JSX.Element {
  const palette = useTheme().palette;

  return (
    <>
      {props.borderList?.length && (
        <AreaChart
          width={368}
          height={260}
          data={props.borderList}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            {borderTypeSequence().map((e) =>
              // @ts-ignore
              LinearGradient(e.name, palette[e.name].main, e.name)
            )}
          </defs>
          <Legend
            iconType="line"
            height={35}
            align="left"
            verticalAlign="top"
            formatter={(v, e, i) => borderTypeSequence()[i].label}
          />
          <XAxis
            dataKey="date"
            tickFormatter={(e) => dateTimeToShortLabel(DateTime.fromISO(e))}
          />
          <YAxis
            tickFormatter={(e) => (e === 0 ? e : `${tenThousandNotation(e)}万`)}
          />
          <CartesianGrid />
          {borderTypeSequence().map((e) => (
            <Area
              type="monotone"
              key={e.name}
              dataKey={e.name}
              // @ts-ignore
              stroke={palette[e.name].main}
              fillOpacity={1}
              fill={`url(#${e.name})`}
            />
          ))}
        </AreaChart>
      )}
    </>
  );
}
