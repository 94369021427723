import { makeStyles, Chip, Typography, Box } from '@material-ui/core';

import React from 'react';

type Entry<T> = {
  label: string;
  value: T;
  icon?: JSX.Element;
};

type Props<T> = {
  label?: string;
  value: Array<T>;
  onChange: (value: Array<T>) => void;
  options: Array<Entry<T>>;
};

const useStyles = makeStyles(() => ({
  container: {
    margin: '.5rem 0 .5rem',
    textAlign: 'center',
  },
  chipsDiv: {
    marginTop: '.3rem',
  },
  chip: {
    margin: '.5rem',
    padding: '0.5rem',
  },
}));

/**
 * 複数選択チップ
 */
export default function MultipleSelectChips<T>(props: Props<T>): JSX.Element {
  const classes = useStyles();

  function handleClick(clickedValue: T) {
    if (props.value.find((e) => e === clickedValue)) {
      const index = props.value.findIndex((e) => e === clickedValue);
      const arr = [...props.value];
      arr.splice(index, 1);
      props.onChange(arr);
    } else {
      props.onChange([...props.value, clickedValue]);
    }
  }

  return (
    <>
      <div className={classes.container}>
        {props.label && (
          <Box mb={4}>
            <Typography variant="body2">{props.label}</Typography>
          </Box>
        )}
        <div className={classes.chipsDiv}>
          {props.options.length
            ? props.options.map((option, i) => (
                <Chip
                  icon={option.icon}
                  className={classes.chip}
                  key={i}
                  color={
                    props.value.find((e) => e === option.value)
                      ? 'primary'
                      : 'default'
                  }
                  label={
                    <Typography variant="body2">{`${option.label}`}</Typography>
                  }
                  clickable
                  onClick={() => handleClick(option.value)}
                />
              ))
            : null}
        </div>
      </div>
    </>
  );
}
