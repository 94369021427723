import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    borderTop: Palette['primary'];
    borderUpper: Palette['primary'];
    borderNormal: Palette['primary'];
    borderLower: Palette['primary'];
    notification: Palette['primary'];
  }

  interface PaletteOptions {
    borderTop: PaletteOptions['primary'];
    borderUpper: PaletteOptions['primary'];
    borderNormal: PaletteOptions['primary'];
    borderLower: PaletteOptions['primary'];
    notification: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: '#007bff',
    },
    background: {
      default: '#999999',
    },
    borderTop: {
      main: '#00b6cb',
    },
    borderUpper: {
      main: '#fa9ed7',
    },
    borderNormal: {
      main: '#0072f0',
    },
    borderLower: {
      main: '#aaaaaa',
    },
    notification: {
      main: '#888888',
    },
  },
  typography: {
    h1: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 10,
    },
  },
  props: {
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
      shrink: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
      notched: true,
    },
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginLeft: 2,
        marginTop: 8,
        marginRight: 2,
        marginBottom: 4,
      },
    },
    MuiFormHelperText: {
      marginDense: {
        // デフォルト
        // fontSIze: 0.8571428571428571rem
        fontSize: '0.7rem',
      },
    },
    MuiInputLabel: {
      marginDense: {
        // デフォルト
        // font-size: 1.1428571428571428rem;
        fontSize: '0.9em',
        '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px, -4px) scale(0.75)',
        },
      },
    },
    MuiOutlinedInput: {
      marginDense: {
        // デフォルト
        // font-size: 1.1428571428571428rem;
        fontSize: '0.9em',
      },
      notchedOutline: {
        '& span': {
          paddingRight: '0',
        },
      },
      inputMarginDense: {
        // デフォルト
        // padding-top: 10.5px;
        // padding-bottom: 10.5px;
        paddingLeft: '10px',
        paddingTop: '7.5px',
        paddingRight: '10px',
        paddingBottom: '5px',
      },
    },
    MuiTableCell: {
      sizeSmall: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  },
});
