import { Box } from '@material-ui/core';
import { Rank } from 'functions/src/model/rank';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import AffiliationLiverLiveTimeTableCard from 'src/component/screen/affiliationliverlivetimetable/AffiliationLiverLiveTimeTableCard';
import { liverRepository } from 'src/model/repository/liverRepository';

type Props = {
  liverId?: number;
};

export function AffiliationLiverLiveTimeTableScreen(props: Props): JSX.Element {
  const [rank, setRank] = useState<Rank | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.liverId) {
      liverRepository
        .fetchRankByLiverId(props.liverId)
        .then((rank) => {
          setRank(rank);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rank]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" my={3} width={1}>
          <Progress />
        </Box>
      ) : (
        <AffiliationLiverLiveTimeTableCard
          liverId={props.liverId}
          rank={rank}
        />
      )}
    </>
  );
}
