import React from 'react';

/**
 * 線形グラデーション
 *
 * @param id - ID
 * @param color - 色
 * @param key - キー
 * @returns 線形グラデーション
 */
export function LinearGradient(
  id: string,
  color: string,
  key?: string
): React.SVGProps<SVGLinearGradientElement> {
  return (
    <linearGradient id={id} key={key} x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={color} stopOpacity={0.8} />
      <stop offset="95%" stopColor={color} stopOpacity={0} />
    </linearGradient>
  );
}
