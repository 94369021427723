import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import React from 'react';

Progress.defaultProps = {
  size: 60,
  color: 'secondary',
};

/**
 * プログレス
 */
export default function Progress(props: CircularProgressProps): JSX.Element {
  return <CircularProgress size={props.size} color={props.color} />;
}
