import { Rank } from 'functions/src/model/rank';
import { firebase } from 'src/model/firebase';

/**
 * ライバーリポジトリ
 */
class LiverRepository {
  /**
   * ランクを取得する
   *
   * @param liverId - ライバーID
   * @returns [Promise]
   */
  fetchRankByLiverId(liverId: number): Promise<Rank> {
    return firebase
      .functions()
      .httpsCallable('fetchRankByLiverId')({
        liverId: liverId,
      })
      .then(
        (result) =>
          // @ts-ignore
          result.data[0].map((e) => e.rank)[0]
      );
  }
}

export const liverRepository = new LiverRepository();
