import TableView from 'src/component/common/TableView';
import { liveStyleType } from 'src/constant/liveStyleType';
import { LiveTime } from 'src/model/liveTime';
import { dateTimeToShortFormat } from 'src/util/dateTime';

type Props = {
  liveTimeList: LiveTime[];
};

/**
 * 自身の配信時間表
 */
export default function LiverLiveTimeTableView(props: Props): JSX.Element {
  return (
    <TableView<LiveTime>
      columnList={[
        {
          header: 'ライブ配信開始時刻',
          getValue: (e) => dateTimeToShortFormat(e.startedAt),
          width: 126,
          isHead: true,
        },
        {
          header: 'おしのび配信かどうか',
          getValue: (e) => liveStyleType[e.isSecretly ? 1 : 0].label,
        },
        {
          header: '配信時間',
          getValue: (e) =>
            e.finishedAt.diff(e.startedAt, 'seconds').toFormat('h:mm'),
          width: 96,
          align: 'right',
        },
      ]}
      data={props.liveTimeList}
      ariaLabel="自身の配信時間"
    />
  );
}
