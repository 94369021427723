import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as app from 'firebase';
import firebaseApp from 'firebase/app';
import 'firebase/auth';
import {
  firebaseConfig,
  rambaiFirebaseConfig,
  samlFirebaseConfig
} from 'src/model/firebase/config';
import Firebase from 'src/model/firebase/firebase';

Sentry.init({
    dsn: "https://8c416c83167c4831b827a926c769ddd0@o152691.ingest.sentry.io/4504890711801856",
    integrations: [new BrowserTracing(),new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1,
  });
  

export type HttpsCallableResult = app.default.functions.HttpsCallableResult;
export const firebase: Firebase = new Firebase(firebaseConfig, 'main');

export const rambaiFirebase: Firebase = new Firebase(
  rambaiFirebaseConfig,
  'rambai'
);

export const samlFirebase = firebaseApp.initializeApp(samlFirebaseConfig);
