import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import React from 'react';

type Props = {
  open?: boolean;
  message: string;
  onClosed?: () => void;
};

HelpDialog.defaultProps = {
  open: true,
};

/**
 * ヘルプダイアログ
 */
export default function HelpDialog(props: Props): JSX.Element {
  function onClosed() {
    props.onClosed && props.onClosed();
  }

  return (
    <Dialog
      open={props.open as boolean}
      onClose={onClosed}
      aria-labelledby="ヘルプ">
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{props.message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosed}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}
