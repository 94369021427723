import { AffiliationLiverLiveTimeRank } from 'functions/src/model/rank';
import SelectBox from 'src/component/common/SelectBox';
import { affiliationLiverLiveTimeRankTypeSequence } from 'src/constant/rankType';

type Props = {
  defaultValue?: AffiliationLiverLiveTimeRank | null;
  onChange: (rank: AffiliationLiverLiveTimeRank | null) => void;
  disabled?: boolean;
  allowUnselected?: boolean;
};

AffiliationLiverLiveTimeRankPicker.defaultProps = {
  allowUnselected: false,
};

/**
 * 事務所所属ライバーの配信時間の表におけるライバーランクピッカー
 */
export function AffiliationLiverLiveTimeRankPicker(props: Props): JSX.Element {
  return (
    <SelectBox<AffiliationLiverLiveTimeRank>
      id="affiliation-liver-live-time-rank"
      label="ランク"
      width={props.allowUnselected ? 28 : 16}
      menuItemEntryList={affiliationLiverLiveTimeRankTypeSequence().map(
        (e) => ({
          label: e,
          value: e,
        })
      )}
      emptyItemLabel={props.allowUnselected ? '指定しない' : null}
      defaultSelectedIndex={
        props.defaultValue
          ? affiliationLiverLiveTimeRankTypeSequence().indexOf(
              props.defaultValue
            )
          : null
      }
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );
}
