import * as app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

/**
 * Firebase
 */
export default class Firebase {
  private readonly instance: app.default.app.App;

  /**
   * コンストラクタ
   * @param config - 設定
   * @param name - 名前
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(config: Object, name: string) {
    this.instance = app.default.initializeApp(config, name);

    // 開発環境ではエミュレータの Functions にアクセス
    if (process.env.NODE_ENV === 'development') {
      this.instance.functions().useEmulator('localhost', 5001);
    }
  }

  /**
   * Functionsオブジェクトを返す
   * @returns [Functions]
   */
  functions(): app.default.functions.Functions {
    return this.instance.functions();
  }

  /**
   * Firestoreオブジェクトを返す
   * @returns [Firestore]
   */
  firestore(): app.default.firestore.Firestore {
    return this.instance.firestore();
  }
}
