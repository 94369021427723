import { rambaiFirebase } from 'src/model/firebase';

/**
 * ライバーIDリポジトリ
 */
class LiverIdRepository {
  /**
   * [urlKey]に紐付いたライバーIDを取得する
   * @param urlKey - URLキー
   * @returns [Promise]
   */
  fetchLiverId(urlKey: string): Promise<number> {
    return rambaiFirebase
      .firestore()
      .collection('liver')
      .doc(urlKey)
      .get()
      .then((doc) => {
        // @ts-ignore
        return Number(doc.data()['liverid']);
      });
  }
}

export const liverIdRepository = new LiverIdRepository();
