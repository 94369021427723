import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Progress from 'src/component/common/Progress';
import FullHeightContentCard from 'src/component/common/layout/FullHeightContentCard';
import DatePicker from 'src/component/common/picker/DatePicker';
import TopFunRankingListView from 'src/component/screen/topfunrankingtable/TopFunRankingListView';
import { topFunRepository } from 'src/model/repository/topFunRepository';
import { TopFunRanking } from 'src/model/topFunRanking';
import { yesterday } from 'src/util/dateTime';

type Props = {
  liverId: number;
};

export default function TopFunRankingListCard(props: Props): JSX.Element {
  const defaultDateTime = yesterday();
  const [dateTime, setDateTime] = useState<DateTime>(defaultDateTime);
  const [loading, setLoading] = useState(false);

  const [topFunRankingList, setTopFunRankingList] = useState<TopFunRanking[]>(
    []
  );

  function onChanged(dateTime: DateTime | null) {
    dateTime && setDateTime(dateTime);
  }

  useEffect(() => {
    setTopFunRankingList([]);
    setLoading(true);

    topFunRepository
      .fetchTopFunRanking(props.liverId, dateTime)
      .then((list) => {
        console.table(list);
        setTopFunRankingList(list);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime]);

  return (
    <FullHeightContentCard
      title="トップファンランキング"
      helpMessage="指定した日付のトップファンランキングです。"
      controlPanel={
        <DatePicker
          label="日付"
          defaultValue={defaultDateTime}
          disabled={loading}
          onChange={onChanged}
        />
      }
      notification="Pocochaアプリで事務所への情報提供を承諾しているリスナーさんのみが表示されます。"
      content={
        loading ? (
          <Box m={3}>
            <Progress />
          </Box>
        ) : (
          <TopFunRankingListView topFunRankingList={topFunRankingList} />
        )
      }
    />
  );
}
