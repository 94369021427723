import TableView from 'src/component/common/TableView';
import { AffiliationLiverLiveTime } from 'src/model/affiliationLiverLiveTime';
import { secondsToTimeString } from 'src/util/dateTime';

type Props = {
  affiliationLiverLiveTimeList: AffiliationLiverLiveTime[];
};

/**
 * 事務所所属ライバーの平均配信時間表
 */
export default function AffiliationLiverLiveTimeTableView(
  props: Props
): JSX.Element {
  return (
    <TableView<AffiliationLiverLiveTime>
      columnList={[
        {
          header: '週',
          getValue: (e) =>
            `${e.year}年${`0${e.month}`.slice(-2)}月 ${e.week}週`,
          width: 108,
          isHead: true,
        },
        {
          header: 'ランク',
          getValue: (e) => e.rank,
          width: 54,
        },
        {
          header: '平均配信時間',
          getValue: (e) => secondsToTimeString(e.averageLiveTimeSeconds),
          align: 'right',
        },
        {
          header: '平均配信日数',
          getValue: (e) => e.averageLiveDays,
          align: 'right',
        },
      ]}
      data={props.affiliationLiverLiveTimeList}
      ariaLabel="事務所所属ライバーの平均配信時間"
    />
  );
}
