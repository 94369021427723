/**
 * Firebaseの設定
 */
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

/**
 * rambaiのFirebaseの設定
 */
export const rambaiFirebaseConfig = {
  apiKey: process.env.REACT_APP_RAMBAI_API_KEY,
  authDomain: process.env.REACT_APP_RAMBAI_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_RAMBAI_PROJECT_ID,
  storageBucket: process.env.REACT_APP_RAMBAI_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_RAMBAI_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_RAMBAI_APP_ID,
  measurementId: process.env.REACT_APP_RAMBAI_MEASUREMENT_ID,
};

/**
 * SAMLプロバイダのFirebaseの設定
 */
export const samlFirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  /* SAML AUTH_DOMAIN 指定 */
  authDomain: process.env.REACT_APP_SAML_AUTH_DOMAIN, 
};