import { DateTime } from 'luxon';
import { EventBorder } from 'src/model/EventBorder';
import { firebase } from 'src/model/firebase';

/**
 * イベント最終日応援ptボーダーリポジトリ
 */
class EventLastDayBorderRepository {
  /**
   * イベント最終日の応援ptボーダーを取得する
   *
   * @param begin - 開始日付
   * @param end - 終了日付
   * @param eventNameKeywordList - イベント名検索キーワードリスト
   * @returns [Promise]
   */
  fetchEventLastDayBorder(
    begin: DateTime | null,
    end: DateTime | null,
    eventNameKeywordList: string[] | null | undefined
  ): Promise<EventBorder[]> {
    return firebase
      .functions()
      .httpsCallable('fetchEventLastDayBorder')({
        begin: begin && {
          year: begin.year,
          month: begin.month,
          day: begin.day,
        },
        end: end && {
          year: end.year,
          month: end.month,
          day: end.day,
        },
        eventNameKeywordList: eventNameKeywordList,
      })
      .then((result) => {
        // @ts-ignore
        return result.data[0].map((e) => ({
          ...e,
          startedDate: DateTime.fromISO(e.startedDate.value),
          endedDate: DateTime.fromISO(e.endedDate.value),
        }));
      });
  }
}

export const eventLastDayBorderRepository = new EventLastDayBorderRepository();
