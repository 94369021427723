import { Container as ContainerImpl, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

type Props = {
  children: JSX.Element;
};

/**
 * コンテナ
 */
export default function Container(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <ContainerImpl className={classes.container}>
      {props.children}
    </ContainerImpl>
  );
}
