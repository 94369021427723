import { Rank } from 'functions/src/model/rank';
import SelectBox from 'src/component/common/SelectBox';
import { rankTypeSequence } from 'src/constant/rankType';

type Props = {
  disabled?: boolean;
  defaultValue?: Rank | null;
  onChange: (rank: Rank | null) => void;
  allowUnselected?: boolean;
};

RankPicker.defaultProps = {
  allowUnselected: false,
};

/**
 * ライバーランクピッカー
 */
export function RankPicker(props: Props): JSX.Element {
  return (
    <SelectBox<Rank>
      id="rank"
      label="ランク"
      width={props.allowUnselected ? 28 : 16}
      menuItemEntryList={rankTypeSequence().map((e) => ({
        label: e,
        value: e,
      }))}
      emptyItemLabel={props.allowUnselected ? '指定しない' : null}
      defaultSelectedIndex={
        props.defaultValue
          ? rankTypeSequence().indexOf(props.defaultValue)
          : null
      }
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );
}
