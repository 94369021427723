import { Box, CssBaseline } from '@material-ui/core';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Home from 'src/component/Home';
import Progress from 'src/component/common/Progress';
import { liverIdRepository } from 'src/model/repository/liverIdRepository';

export default function App(): JSX.Element {
  const [liverId, setLiverId] = useState<number>();
  const [loading, setLoading] = useState(true);

  // @ts-ignore
  const { urlKey } = useParams();

  useEffect(() => {
    if (urlKey) {
      liverIdRepository
        .fetchLiverId(urlKey)
        .then((liverId) => {
          console.log(`set liverId as ${liverId}`);
          setLiverId(liverId);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 認証周りをコメントアウト
  // useEffect(() => {
  //   const createUser = async () => {
  //     await firebase.auth().getRedirectResult()
  //     .then((result) => {
  //       const decoded_json = JSON.stringify(result)
  //       const json = JSON.parse(decoded_json)
  //       if (!result?.user) return
  //       setUser(JSON.stringify(json.additionalUserInfo.profile));
  //     }).catch((error) => {
  //       alert(error.message);
  //       console.error("auth error="+error);
  //     });
  //     setUserLoading(false);
  //   }
  //   createUser()
  //   setUserLoading(true)
  // }, [])

  return (
    <>
      <CssBaseline />
      {loading ? (
        <Box display="flex" justifyContent="center" m={3} width={1}>
          <Progress />
        </Box>
      ) : (
        <Home liverId={liverId} />
      )}
    </>
  );
}
