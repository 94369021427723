import React from 'react';
import LiverLiveTimeTableCard from 'src/component/screen/liverlivetimetable/LiverLiveTimeTableCard';

type Props = {
  liverId: number;
};

export function LiverLiveTimeTableScreen(props: Props): JSX.Element {
  return <LiverLiveTimeTableCard liverId={props.liverId} />;
}
