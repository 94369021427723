import ListView from 'src/component/common/ListView';
import EventBorderCard from 'src/component/screen/eventborderlist/EventBorderCard';
import { EventBorder } from 'src/model/EventBorder';

type Props = {
  eventBorderList: EventBorder[];
};

/**
 * イベント最終日の応援ptボーダーリスト
 */
export default function EventBorderListView(props: Props): JSX.Element {
  return (
    <ListView
      data={props.eventBorderList}
      itemKey={(e) =>
        `${e.startedDate.toISODate()}-${e.eventName}` +
        '-' +
        `${e.stageName}-${e.blockName}`
      }
      itemContent={(e) => <EventBorderCard eventBorder={e} />}
      itemSize={190}
    />
  );
}
