import { DayOfWeek } from 'functions/src/model/dayOfWeek';
import { DateTime } from 'luxon';

/**
 * 昨日の[DateTime]を返す
 *
 * @returns 昨日の[DateTime]
 */
export function yesterday(): DateTime {
  return DateTime.now().minus({ days: 1 });
}

/**
 * 一昨日の[DateTime]を返す
 *
 * @returns 一昨日の[DateTime]
 */
 export function dayBeforeYesterday(): DateTime {
  return DateTime.now().minus({ days: 2 });
}

/**
 * [DateTime]の曜日を返す
 *
 * @param dateTime - [DateTime]
 * @returns 曜日
 */
export function normalizedDayOfWeek(dateTime: DateTime): DayOfWeek {
  return ((dateTime.weekday % 7) + 1) as DayOfWeek;
}

/**
 * 先月一日の[DateTime]を返す
 *
 * @returns 先月一日の[DateTime]
 */
export function lastMonth(): DateTime {
  return DateTime.now().startOf('month').minus({ months: 1 });
}

/**
 * [DateTime]を月と日のみの文字列へ変換する
 *
 * @param dateTime - [DateTime]
 * @returns 月と日のみの文字列
 */
export function dateTimeToShortLabel(dateTime: DateTime): string {
  return `${dateTime.month}月${dateTime.day}日`;
}

/**
 * [DateTime]を年と月と日のみの文字列へ変換する
 *
 * @param dateTime - [DateTime]
 * @returns 年と月と日のみの文字列
 */
export function dateTimeToLabel(dateTime: DateTime): string {
  return `${dateTime.year}年${dateTime.month}月${dateTime.day}日`;
}

/**
 * [DateTime]を省略表記の文字列へ変換する
 *
 * @param dateTime - [DateTime]
 * @returns 省略表記の文字列
 */
export function dateTimeToShortFormat(dateTime: DateTime): string {
  return dateTime.toFormat('yyyy/MM/dd HH:mm');
}

/**
 * 秒を時間文字列へ変換する
 *
 * 時間文字列のフォーマットは時:分。
 *
 * @param seconds - 秒
 * @returns 時間文字列
 */
export function secondsToTimeString(seconds: number): string {
  return [2, 1]
    .reduce(
      (result, dimen) => ({
        left: result.left % 60 ** dimen,
        elements: [...result.elements, Math.floor(result.left / 60 ** dimen)],
      }),
      {
        left: seconds,
        elements: [] as number[],
      }
    )
    .elements.map((e) => `0${e}`.slice(-2))
    .join(':');
}
