import { Box } from '@material-ui/core';
import React from 'react';

type Props = {
  index: number;
  value: number;
  children: JSX.Element;
};

/**
 * タブパネル
 */
export function TabPanel(props: Props): JSX.Element {
  return (
    <Box
      id={`tabpanel-${props.index}`}
      role="tabpanel"
      height={1}
      hidden={props.value !== props.index}
      aria-labelledby={`tab-${props.index}`}>
      {props.value === props.index && props.children}
    </Box>
  );
}
