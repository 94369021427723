import { makeStyles, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import { Listener } from 'src/model/listener';
import { ListenerCoinChange } from 'src/model/listenerCoinChange';
import { dateTimeToShortLabel } from 'src/util/dateTime';
import { thousandNotation } from 'src/util/number';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    verticalAlign: 'text-bottom',
  },
}));

export type Entry = {
  name: string;
  imageUrl: string;
} & ListenerCoinChange;

export type ColorMap = Map<number, string>;

type Props = {
  entryList: Entry[];
  colorMap: ColorMap;
};

/**
 * リスナーのコイン推移グラフ
 */
export default function ListenerCoinChangesGraphView(
  props: Props
): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const root = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const data = Array.from(
    new Set(props.entryList.map((e) => e.date.toISODate()))
  )
    .map((dateString) =>
      props.entryList
        .filter((e) => e.date.toISODate() === dateString)
        .reduce(
          // @ts-ignore
          (result, next) => {
            return {
              date: dateString,
              listenerCoin: [...result.listenerCoin, [next.id, next.coin]],
            };
          },
          {
            date: dateString,
            listenerCoin: [],
          }
        )
    )
    .map((e) => ({
      // @ts-ignore
      date: e.date,
      // @ts-ignore
      ...Object.fromEntries(e.listenerCoin),
    }));

  const listenerList = Array.from(
    new Set(props.entryList.map((e) => e.id))
  ).map((e) => {
    const listener = props.entryList.find((ee) => ee.id === e);

    return {
      id: e,
      // @ts-ignore
      name: listener.name,
      // @ts-ignore
      imageUrl: listener.imageUrl,
    };
  });

  function legendFormat(listener: Listener): JSX.Element {
    return (
      <>
        <img
          src={listener.imageUrl}
          alt={listener.name}
          className={classes.avatar}
        />
        <span>{listener.name}</span>
      </>
    );
  }

  useEffect(() => {
    // @ts-ignore
    setHeight(root.current.getBoundingClientRect().height - 15);
    // @ts-ignore
    setWidth(root.current.getBoundingClientRect().width - 20);
  }, []);

  return (
    <div ref={root} className={classes.root}>
      {props.entryList?.length ? (
        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{ top: 10, right: 20, left: 0, bottom: 5 }}>
          <Legend
            iconType="line"
            align="left"
            verticalAlign="top"
            wrapperStyle={{
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingBottom: theme.spacing(4),
            }}
            formatter={(v) => {
              // @ts-ignore
              return legendFormat(listenerList.find((e) => v === e.id));
            }}
          />
          <XAxis
            dataKey="date"
            tickFormatter={(e) => {
              return dateTimeToShortLabel(DateTime.fromISO(e));
            }}
          />
          <YAxis
            tickFormatter={(e) => (e === 0 ? e : `${thousandNotation(e)}千`)}
          />
          <CartesianGrid />
          {listenerList.map((e) => (
            // @ts-ignore
            <Line
              key={e.id}
              dataKey={e.id}
              stroke={props.colorMap.get(e.id)}
              dot={false}
            />
          ))}
        </LineChart>
      ) : (
        <></>
      )}
    </div>
  );
}
