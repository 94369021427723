import {
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import React from 'react';

type Props = {
  id: string;
  label: string;
  width?: number | string;
  defaultValue?: string;
  placeholder?: string;
  autoComplete?: string;
  onChange?: (text: string | null) => void;
  disabled?: boolean;
};

TextInput.defaultProps = {
  width: 'auto',
  autoComplete: 'off',
  disabled: false,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  outlinedInput: (props: Props) => ({
    width: props.width,
    height: theme.spacing(9),
  }),
}));

/**
 * テキスト入力
 */
export default function TextInput(props: Props): JSX.Element {
  const classes = useStyles(props);

  // eslint-disable-next-line @typescript-eslint/ban-types
  function onKeyDown(event: Object) {
    // @ts-ignore
    if (event.keyCode === 13) {
      // @ts-ignore
      event.target.blur();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  function onBlur(event: Object) {
    // @ts-ignore
    const text = event.target.value;
    props.onChange && props.onChange(text || null);
  }

  return (
    <FormControl
      className={classes.container}
      margin="dense"
      disabled={props.disabled}>
      <InputLabel
        id={`${props.id}-label`}
        variant="outlined"
        htmlFor={props.id}>
        {props.label}
      </InputLabel>
      <OutlinedInput
        id={props.id}
        className={classes.outlinedInput}
        label={`${props.id}-label`}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    </FormControl>
  );
}
