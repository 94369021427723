import { Rank } from 'functions/src/model/rank';
import { DateTime } from 'luxon';
import { AffiliationLiverLiveTime } from 'src/model/affiliationLiverLiveTime';
import { firebase } from 'src/model/firebase';
import { LiveTime } from 'src/model/liveTime';

/**
 * ライブリポジトリ
 */
class LiveRepository {
  /**
   * ライバーの配信時間を取得する
   *
   * @param liverId - ライバーID
   * @param isSecretly - おしのび配信か
   * @param date - 日付
   * @returns [Promise]
   */
  fetchLiveTime(
    liverId: number,
    isSecretly: boolean | null,
    date: DateTime | null
  ): Promise<LiveTime[]> {
    return firebase
      .functions()
      .httpsCallable('fetchLiveTime')({
        liverId: liverId as number,
        isSecretly: isSecretly,
        date: date && {
          year: date.year,
          month: date.month,
          day: date.day,
        },
      })
      .then((result) =>
        // @ts-ignore
        result.data[0].map((e) => ({
          id: e.id,
          isSecretly: e.isSecretly,
          startedAt: DateTime.fromISO(e.startedAt.value),
          finishedAt: DateTime.fromISO(e.finishedAt.value),
        }))
      );
  }

  /**
   * 事務所所属ライバーの平均配信時間を取得する
   *
   * 週のランク毎の平均配信時間（秒）と平均配信日数を返す。
   *
   * @param year - 年
   * @param month - 月
   * @param rank - ライバーランク
   * @returns [Promise]
   */
  fetchAffiliationLiverLiveTime(
    year: number | null | undefined,
    month: number | null | undefined,
    rank: Rank | 'S/A' | null
  ): Promise<AffiliationLiverLiveTime[]> {
    return firebase
      .functions()
      .httpsCallable('fetchAffiliationLiverLiveTime')({
        year: year,
        month: month,
        rank: rank,
      })
      .then(
        // @ts-ignore
        (result) => result.data[0]
      );
  }
}

export const liveRepository = new LiveRepository();
