import { Box, makeStyles, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { borderTypeSequence } from 'src/constant/borderType';
import { dayOfWeekSymbol } from 'src/constant/dayOfWeekSymbol';
import { Border } from 'src/model/border';
import { normalizedDayOfWeek } from 'src/util/dateTime';
import { tenThousandNotation } from 'src/util/number';

const useStyles = makeStyles(() => ({
  name: {
    fontSize: 24,
    fontWeight: 'bold',
  },
}));

type Props = {
  name: string;
  borderList: Border[];
};

type BorderData = {
  borderTop: number;
  borderUpper: number;
  borderNormal: number;
  borderLower: number;
};

/**
 * [borderDataList]の平均を返す
 *
 * @param borderDataList - [BorderData[]]
 * @returns [borderDataList]の平均
 */
function average(borderDataList: BorderData[]): BorderData {
  const sum = borderDataList.reduce(
    (total, next) => ({
      borderTop: total.borderTop + next.borderTop,
      borderUpper: total.borderUpper + next.borderUpper,
      borderNormal: total.borderNormal + next.borderNormal,
      borderLower: total.borderLower + next.borderLower,
    }),
    {
      borderTop: 0,
      borderUpper: 0,
      borderNormal: 0,
      borderLower: 0,
    }
  );

  return borderDataList.length
    ? {
        borderTop: sum.borderTop / borderDataList.length,
        borderUpper: sum.borderUpper / borderDataList.length,
        borderNormal: sum.borderNormal / borderDataList.length,
        borderLower: sum.borderLower / borderDataList.length,
      }
    : sum;
}

/**
 * 曜日毎のボーダーグラフ
 *
 * ボーダーリストのボーダーのライバーランクは全て同じであることを要求する。
 */
export default function DayOfWeekBorderGraphView(props: Props): JSX.Element {
  const classes = useStyles();

  const graphData = [...Array(7)]
    .map((_, i) => ({
      dayOfWeek: i + 1,
      borderList: props.borderList
        .filter(
          (e: Border) => i + 1 === normalizedDayOfWeek(DateTime.fromISO(e.date))
        )
        .map((e) => ({
          borderTop: e.borderTop || 0,
          borderUpper: e.borderUpper || 0,
          borderNormal: e.borderNormal || 0,
          borderLower: e.borderLower || 0,
        })),
    }))
    .map((e) => ({
      dayOfWeek: e.dayOfWeek,
      border: average(e.borderList),
    }));

  // @ts-ignore
  const CustomLabel = (props) => {
    return (
      <text
        x={props.x}
        y={props.y}
        dx={10}
        dy={-4}
        fontSize="9"
        fontWeight="bold"
        fill={props.fill}
        textAnchor="middle">
        {Math.round(props.value).toLocaleString()}
      </text>
    );
  };

  return (
    <Box>
      {borderTypeSequence().map((type) => (
        <Box key={type.name} position="relative">
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.name}
            position="absolute"
            top={10}
            right={10}>
            {props.name}
          </Box>
          <BarChart
            key={type.name}
            width={365}
            height={230}
            data={graphData}
            barSize={25}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 10,
            }}>
            <CartesianGrid vertical={false} />
            <Legend height={35} align="left" verticalAlign="top" />
            {/* @ts-ignore */}
            <Tooltip labelFormatter={(v) => dayOfWeekSymbol[v]} />
            <XAxis
              dataKey="dayOfWeek"
              // @ts-ignore
              tickFormatter={(e) => dayOfWeekSymbol[e]}
            />
            <YAxis
              // @ts-ignore
              tick={graphData.some((e) => e.border[type.name] !== 0)}
              tickFormatter={(e) =>
                e === 0 ? e : `${tenThousandNotation(e)}万`
              }
              width={58}
            />
            <Bar
              name={type.label}
              dataKey={`border.${type.name}`}
              isAnimationActive={false}
              fill="#81c784">
              {props.borderList.length === 0 || (
                <LabelList
                  dataKey={`border.${type.name}`}
                  position="insideTop"
                  content={<CustomLabel />}
                />
              )}
            </Bar>
          </BarChart>
          {type.name === 'borderNormal' &&
            DateTime.now() < DateTime.local(2021, 6, 1) && (
              <Box color="notification.main" ml={14} lineHeight="0.8">
                <Typography variant="caption">
                  ※±0のグラフは2021年6月より表示されます。
                </Typography>
              </Box>
            )}
        </Box>
      ))}
    </Box>
  );
}
