import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { PeopleAlt } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import MultipleSelectChips from 'src/component/common/MultipleSelectChips';
import { Listener } from 'src/model/listener';
import { topFunRepository } from 'src/model/repository/topFunRepository';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

type Props = {
  liverId: number;
  limit: number;
  onChange: (listenerIdList: Array<number>) => void;
  onListenerLoaded: (listenerList: Listener[]) => void;
  onClosed?: () => void;
  disabled?: boolean;
};

ListenerPicker.defaultProps = {
  allowUnselected: true,
};

/**
 * リスナーピッカー
 */
export function ListenerPicker(props: Props): JSX.Element {
  const classes = useStyles();
  const [isPickerOpened, setPickerOpened] = useState(false);
  const [value, setValue] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const [listenerList, setListenerList] = useState<Listener[] | null>(null);

  function onClosed() {
    props.onChange && props.onChange(value);
    setPickerOpened(false);
  }

  function onClicked() {
    setPickerOpened(true);
  }

  function onChanged(value: Array<number>) {
    setValue(value);
  }

  useEffect(() => {
    if (listenerList == null) {
      topFunRepository
        .fetchListener(props.liverId, props.limit)
        .then((listenerList) => {
          console.table(listenerList);
          props.onListenerLoaded && props.onListenerLoaded(listenerList);
          setListenerList(listenerList);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenerList]);

  return (
    <>
      <IconButton disabled={props.disabled || loading} onClick={onClicked}>
        <PeopleAlt />
      </IconButton>
      <Dialog open={isPickerOpened} onClose={onClosed}>
        <DialogContent>
          <MultipleSelectChips
            label="絞り込むリスナーを選択"
            value={value}
            onChange={onChanged}
            options={
              listenerList?.map((e) => ({
                label: e.name,
                value: e.id,
                icon: (
                  <Avatar
                    alt={e.name}
                    src={e.imageUrl}
                    className={classes.avatar}
                  />
                ),
              })) || []
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosed}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
