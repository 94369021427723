import { Rank } from 'functions/src/model/rank';
import TableView from 'src/component/common/TableView';
import { borderType } from 'src/constant/borderType';
import { rankTypeSequence } from 'src/constant/rankType';
import { Border } from 'src/model/border';

type Props = {
  borderList: Border[];
};

type TableData = {
  rank: Rank;
  border: {
    borderTop: number | null;
    borderUpper: number | null;
    borderNormal: number | null;
    borderLower: number | null;
  } | null;
};

/**
 * ボーダーの値を表示用に変換する
 *
 * @param value - ボーダーの値
 * @returns 表示用の値
 */
function getBorderValue(value: number | null | undefined): number | string {
  return value?.toLocaleString() || '未取得';
}

/**
 * 一日のライバーランク毎のボーダー表
 *
 * ボーダーリストはライバーランクでソートされていることを要求する。
 */
export default function BorderTableView(props: Props): JSX.Element {
  const borderList: TableData[] = rankTypeSequence().map((rank) => {
    const border = props.borderList.find((e) => e.rank === rank);

    return {
      rank: rank,
      border: border
        ? {
            borderTop: border.borderTop,
            borderUpper: border.borderUpper,
            borderNormal: border.borderNormal,
            borderLower: border.borderLower,
          }
        : null,
    };
  });

  return (
    <TableView<TableData>
      columnList={[
        {
          header: 'ランク',
          getValue: (e) => e.rank,
          isHead: true,
          width: '16%',
        },
        {
          header: borderType.top.label,
          getValue: (e) => getBorderValue(e.border?.borderTop),
          align: 'right',
          width: '21%',
        },
        {
          header: borderType.upper.label,
          getValue: (e) => getBorderValue(e.border?.borderUpper),
          align: 'right',
          width: '21%',
        },
        {
          header: borderType.normal.label,
          getValue: (e) => getBorderValue(e.border?.borderNormal),
          align: 'right',
          width: '21%',
        },
        /*
        {
          header: borderType.lower.label,
          getValue: (e) => getBorderValue(e.border?.borderLower),
          align: 'right',
          width: '21%',
        },
        */
      ]}
      data={borderList}
      ariaLabel="ランク別ボーダー"
    />
  );
}
