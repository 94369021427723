import { ListItem, makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

type Props<T> = {
  data: Array<T>;
  itemKey: (e: T) => string;
  itemContent: (e: T) => JSX.Element;
  itemSize: number;
};

export default function ListView<T>(props: Props<T>): JSX.Element {
  const classes = useStyles();
  const root = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // @ts-ignore
    setHeight(root.current.getBoundingClientRect().height);
  }, []);

  return (
    <div ref={root} className={classes.root}>
      <FixedSizeList
        height={height}
        width="100%"
        itemSize={props.itemSize}
        itemCount={props.data.length}>
        {({ index, style }) => {
          const e = props.data[index];

          return (
            <ListItem key={props.itemKey(e)} style={style} dense disableGutters>
              {props.itemContent(e)}
            </ListItem>
          );
        }}
      </FixedSizeList>
    </div>
  );
}
